<!--
 * @Description: 商品分类三级分类Form页面
 * @Author: 小广
 * @Date: 2019-06-05 13:37:27
 * @LastEditors: 蒙川
 * @LastEditTime: 2019-08-02 19:07:10
 -->
<template>
  <div class="goodsCategoryThirdForm-wrapper">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      @update="update"
    >
      <col2-detail>
        <col2-block>
          <col2-item
            :span="24"
            label="一级类目"
            :text="detailItem.oneCategoryName"
          />
          <col2-item
            :span="24"
            label="二级类目"
            :text="detailItem.twoCategoryName"
          />
          <el-form-item label="三级类目">
            <span>{{ detailItem.threeCategoryName }}</span>
          </el-form-item>
          <el-form-item label="分类名称">
            <div class="content-wrapper">
              <v-input
                v-model="form.categoryAlias"
                placeholder="分类名称"
                :maxlength="6"
              ></v-input>
              <span class="right-tip"
                >注：最多输入6位，为空时按照类目名称显示</span
              >
            </div>
          </el-form-item>
          <el-form-item label="图标">
            <img class="img-wrapper" :src="form.categoryPic">
          </el-form-item>
          <el-form-item label="排序">
            <div class="content-wrapper">
              <v-input-number
                v-model="form.sort"
                placeholder="请输入排序"
                :min="1"
                :max="9999"
              ></v-input-number>
              <span class="right-tip">注：为空时按照默认条件进行排序</span>
            </div>
          </el-form-item>
          <el-form-item
            label="状态"
            prop="showStatus"
            :rules="{
              required: true,
              message: '当前选项不允许为空',
              trigger: 'change'
            }"
          >
            <v-select v-model="form.showStatus" :options="statusOps"></v-select>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { getDetailURL, editURL } from './api'
import { statusOps } from './map'
import { Col2Block, Col2Item, Col2Detail } from 'components/bussiness'

export default {
  name: '',
  components: {
    Col2Block,
    Col2Item,
    Col2Detail
  },
  data () {
    return {
      submitConfig: {
        queryUrl: getDetailURL,
        submitUrl: editURL,
        submitMethod: 'put'
      },
      // 除去全部
      statusOps: statusOps.slice(1, statusOps.length),
      form: {
        id: this.$route.query.id,
        categoryAlias: '',
        categoryPic: '',
        showStatus: 1,
        sort: undefined,
        regionId: this.$route.query.regionId
      },
      detailItem: {
        oneCategoryName: '',
        twoCategoryName: '',
        threeCategoryName: ''
      }
    }
  },

  mounted () {
    const { id, title, regionId, sTitle, regionName } = this.$route.query
    this.$setBreadcrumb(`${regionName} > ${sTitle} > ${title} > 编辑`)
    this.$refs.formPanel.getData({ id, regionId })
  },

  methods: {
    update (data) {
      Object.keys(this.detailItem).forEach(key => {
        if (data[key]) {
          this.detailItem[key] = data[key]
        }
      })

      Object.keys(this.form).forEach(key => {
        if (data[key]) {
          this.form[key] = data[key]
        }
      })

      this.$set(this.form, 'showStatus', parseInt(data.showStatus))
    }
  }
}
</script>

<style lang="scss" scoped>
.goodsCategoryThirdForm-wrapper {
  .img-wrapper {
    width: 160px;
    height: 160px;
    border: 1px solid #d5dbeb;
    border-radius: 4px
  }

  .content-wrapper {
    display: flex;
    justify-content: flex-start;

    .right-tip {
      margin-left: 15px;
    }
  }
}
</style>
