var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goodsCategoryThirdForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "一级类目",
                      text: _vm.detailItem.oneCategoryName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "二级类目",
                      text: _vm.detailItem.twoCategoryName,
                    },
                  }),
                  _c("el-form-item", { attrs: { label: "三级类目" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.detailItem.threeCategoryName)),
                    ]),
                  ]),
                  _c("el-form-item", { attrs: { label: "分类名称" } }, [
                    _c(
                      "div",
                      { staticClass: "content-wrapper" },
                      [
                        _c("v-input", {
                          attrs: { placeholder: "分类名称", maxlength: 6 },
                          model: {
                            value: _vm.form.categoryAlias,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "categoryAlias", $$v)
                            },
                            expression: "form.categoryAlias",
                          },
                        }),
                        _c("span", { staticClass: "right-tip" }, [
                          _vm._v("注：最多输入6位，为空时按照类目名称显示"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "图标" } }, [
                    _c("img", {
                      staticClass: "img-wrapper",
                      attrs: { src: _vm.form.categoryPic },
                    }),
                  ]),
                  _c("el-form-item", { attrs: { label: "排序" } }, [
                    _c(
                      "div",
                      { staticClass: "content-wrapper" },
                      [
                        _c("v-input-number", {
                          attrs: {
                            placeholder: "请输入排序",
                            min: 1,
                            max: 9999,
                          },
                          model: {
                            value: _vm.form.sort,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sort", $$v)
                            },
                            expression: "form.sort",
                          },
                        }),
                        _c("span", { staticClass: "right-tip" }, [
                          _vm._v("注：为空时按照默认条件进行排序"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "状态",
                        prop: "showStatus",
                        rules: {
                          required: true,
                          message: "当前选项不允许为空",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.statusOps },
                        model: {
                          value: _vm.form.showStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "showStatus", $$v)
                          },
                          expression: "form.showStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }